export const siapeServidorMask = value => {
  if (value.length > 7) {
    let v = value.replace(/^(?![1-9])0/g, "");
    /*if (v.length >= 8) {
      v = v.substring(0, v.length - 1);
      console.log(v);
    }*/
    return v;
  } else {
    let s = value;
    while (s.length < 7) {
      s = "0" + s;
    }
    return s;
  }
};

export const siapePensionistaMask = value => {
  if (value.length > 8) {
    let v = value.replace(/^(?![1-9])0/g, "");
    /*if (v.length >= 8) {
      v = v.substring(0, v.length - 1);
      console.log(v);
    }*/
    return v;
  } else {
    let s = value;
    while (s.length < 8) {
      s = "0" + s;
    }
    return s;
  }
};
