<template>
  <md-table v-model="vinculos" @md-selected="onSelect">
    <div v-if="!isFetching">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div style="padding-top:15px;display:inline-block;">
            <span>
              <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
                <small>{{ tipoProprietario }} id: {{ form.idServidor }}</small>
              </h5></span
            >
          </div>
          <div class="switch-div" v-show="checkPapel()">
            <md-switch v-model="editionMode">Modo Edição </md-switch>
          </div>
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Matrícula e Regime Juridico do Servidor
            </h5>
          </span>

          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.siapeServidor"
                :rules="{
                  regex: /^[0-9][0-9][0-9][0-9][1-9]{3}$/,
                  min: 7,
                  max: 7,
                  required: true,
                  numeric: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Siape Servidor</label>
                  <md-input
                    v-model="form.siapeServidor"
                    type="text"
                    :maxlength="8"
                    :disabled="checkEditionDisabled()"
                    :key="componentKeyToRerender"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Siape deve ter 7 digitos, ao menos 3 diferente de 0." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="form.siapeServidorDv"
                :rules="{
                  regex: /^\d{1}$/,
                  min: 1,
                  max: 1
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>DV Siape</label>
                  <md-input
                    v-model="form.siapeServidorDv"
                    type="text"
                    :maxlength="1"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "DV Siape deve ter 1 digito." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.sigRegJur"
                :rules="{
                  min: 3,
                  max: 20,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Sigla Regime Jurídico</label>
                  <md-input
                    v-model="form.sigRegJur"
                    type="text"
                    :maxlength="20"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.regimeJuridico"
                :rules="{
                  min: 3,
                  max: 20,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Regime Jurídico</label>
                  <md-input
                    v-model="form.regimeJuridico"
                    type="text"
                    :maxlength="20"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <md-button
                class="md-raised md-info mt-3"
                v-show="checkPapel()"
                @click="goToHistoricoVinculos()"
                ><md-icon>history</md-icon>Histórico de Vinculos
              </md-button>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Orgão
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.codOrgao"
                :rules="{
                  regex: /^\d{5}$/,
                  min: 5,
                  max: 5,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Código Orgão</label>
                  <md-input
                    v-model="form.codOrgao"
                    type="text"
                    :maxlength="5"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Código Orgão deve ter 5 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.orgDescricao"
                :rules="{
                  alpha_spaces: true,
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Orgão Descrição</label>
                  <md-input
                    v-model="form.orgDescricao"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.descricaoIngOrgao"
                :rules="{
                  alpha_spaces: true,
                  min: 3,
                  max: 100
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Descrição Ingresso Orgão</label>
                  <md-input
                    v-model="form.descricaoIngOrgao"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dataIngressoOrgao"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data Ingresso Orgão</label>
                  <md-input
                    v-model="form.dataIngressoOrgao"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.descricaoIngServPublico"
                :rules="{
                  alpha_spaces: true,
                  min: 3,
                  max: 100
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Descrição Ingresso Serviço Público</label>
                  <md-input
                    v-model="form.descricaoIngServPublico"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.dtIngServPublico"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data Ingresso Serviço Público</label>
                  <md-input
                    v-model="form.dtIngServPublico"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Unidade Pagadora
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.upag"
                :rules="{
                  regex: /^\d{9}$/,
                  min: 9,
                  max: 9,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Pagadora</label>
                  <md-input
                    v-model="form.upag"
                    type="text"
                    :maxlength="9"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Unidade Pagadora deve ter 9 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.upagDescricao"
                :rules="{
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Pagadora Descrição</label>
                  <md-input
                    v-model="form.upagDescricao"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.siglaUpag"
                :rules="{
                  min: 3,
                  max: 20,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Sigla Unidade Pagadora</label>
                  <md-input
                    v-model="form.siglaUpag"
                    type="text"
                    :maxlength="20"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ufUpag"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ufUpagSelect">UF da Upag</label>
                  <md-select
                    id="selectOptionFieldUfUpag"
                    v-model="ufUpagSelect"
                    name="ufUpagSelect"
                    @md-opened="mdSelectScrollResizeCall()"
                    @md-selected="selectUfUpag"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Unidade Exercício
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.uorgExercicio"
                :rules="{
                  regex: /^\d{9}$/,
                  min: 9,
                  max: 9,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Exercício</label>
                  <md-input
                    v-model="form.uorgExercicio"
                    type="text"
                    :maxlength="9"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Unidade Exercício deve ter 9 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeUnidadeExercicio"
                :rules="{
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Exercício Descrição</label>
                  <md-input
                    v-model="form.nomeUnidadeExercicio"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.siglaUnidadeExercicio"
                :rules="{
                  min: 3,
                  max: 20,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Sigla Unidade Exercício</label>
                  <md-input
                    v-model="form.siglaUnidadeExercicio"
                    type="text"
                    :maxlength="20"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ufUnidadeExercicio"
                :rules="{
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ufUnidadeExercicioSelect">UF do Exercício</label>
                  <md-select
                    id="selectOptionFieldUfUnidadeExercicio"
                    v-model="ufUnidadeExercicioSelect"
                    name="ufUnidadeExercicioSelect"
                    @md-selected="selectUfUnidadeExercicio"
                    @md-opened="mdSelectScrollResizeCall()"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Cargo
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.grupoCargo"
                :rules="{
                  regex: /^\d{6}$/,
                  min: 6,
                  max: 6,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Grupo do Cargo</label>
                  <md-input
                    v-model="form.grupoCargo"
                    type="text"
                    :maxlength="6"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Grupo do Cargo deve ter 6 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.cargoDescricao"
                :rules="{
                  min: 3,
                  max: 100,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Cargo Descrição</label>
                  <md-input
                    v-model="form.cargoDescricao"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="form.classeCargo"
                :rules="{
                  min: 1,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Classe do Cargo</label>
                  <md-input
                    v-model="form.classeCargo"
                    type="text"
                    :maxlength="2"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="form.padraoCargo"
                :rules="{
                  min: 1,
                  max: 3
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Padrão do Cargo</label>
                  <md-input
                    v-model="form.padraoCargo"
                    type="text"
                    :maxlength="3"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-10">
              <ValidationProvider
                name="form.nivelCargo"
                :rules="{
                  min: 1,
                  max: 2,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nível do Cargo</label>
                  <md-input
                    v-model="form.nivelCargo"
                    type="text"
                    :maxlength="2"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Lotação
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.uorgLotacao"
                :rules="{
                  regex: /^\d{9}$/,
                  min: 9,
                  max: 9
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Lotação</label>
                  <md-input
                    v-model="form.uorgLotacao"
                    type="text"
                    :maxlength="9"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Unidade Lotação deve ter 9 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeUnidadeLotacao"
                :rules="{
                  min: 3,
                  max: 100
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Lotação Descrição</label>
                  <md-input
                    v-model="form.nomeUnidadeLotacao"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.sigleUnidadeLotacao"
                :rules="{
                  min: 3,
                  max: 20
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Sigla Unidade Lotação</label>
                  <md-input
                    v-model="form.siglaUnidadeLotacao"
                    type="text"
                    :maxlength="20"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ufUnidadeLotacao"
                :rules="{
                  required: false
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ufUnidadeLotacaoSelect">UF da Lotação</label>
                  <md-select
                    id="selectOptionFieldUfUnidadeLotacao"
                    v-model="ufUnidadeLotacaoSelect"
                    name="ufUnidadeLotacaoSelect"
                    @md-selected="selectUfUnidadeLotacao"
                    @md-opened="mdSelectScrollResizeCall()"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Localização
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.uorgLocalizacao"
                :rules="{
                  regex: /^\d{9}$/,
                  min: 9,
                  max: 9,
                  numeric: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Localização</label>
                  <md-input
                    v-model="form.uorgLocalizacao"
                    type="text"
                    :maxlength="9"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Unidade Localização deve ter 9 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeUnidadeLocalizacao"
                :rules="{
                  min: 3,
                  max: 100
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Unidade Localização Descrição</label>
                  <md-input
                    v-model="form.nomeUnidadeLocalizacao"
                    type="text"
                    :maxlength="100"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.siglaUnidadeLocalizacao"
                :rules="{
                  min: 3,
                  max: 20
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Sigla Unidade Localização</label>
                  <md-input
                    v-model="form.siglaUnidadeLocalizacao"
                    type="text"
                    :maxlength="20"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.ufUnidadeLocalizacao"
                :rules="{
                  required: false
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="ufUnidadeLocalizacaoSelect"
                    >UF da Localização</label
                  >
                  <md-select
                    id="selectOptionFieldUfUnidadeLocalizacao"
                    v-model="ufUnidadeLocalizacaoSelect"
                    name="ufUnidadeLocalizacaoSelect"
                    @md-selected="selectUfUnidadeLocalizacao"
                    @md-opened="mdSelectScrollResizeCall()"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-option
                      v-for="item in estados"
                      :key="item.shortname"
                      :label="item.name"
                      :value="item.shortname"
                    >
                      {{ item.shortname }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <span>
            <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
              Aposentadoria
            </h5>
          </span>
          <div id="agrupamentoCampos" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.grupoAposentadoria"
                :rules="{
                  regex: /^(?:\d{1}|\d{2}|\d{3})$/,
                  min: 1,
                  max: 3
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Grupo Aposentadoria</label>
                  <md-input
                    v-model="form.grupoAposentadoria"
                    type="text"
                    :maxlength="3"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Grupo Aposentadoria deve ter de 1 até 3 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.codAposentadoria"
                :rules="{
                  min: 1,
                  max: 3
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Código Aposentadoria</label>
                  <md-input
                    v-model="form.codAposentadoria"
                    type="text"
                    :maxlength="3"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dtAposentadoria"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data Aposentadoria</label>
                  <md-input
                    v-model="form.dtAposentadoria"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <ValidationProvider
                name="form.ocorrenciaAposentadoria"
                :rules="{
                  min: 3,
                  max: 50
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Ocorrência Aposentadoria</label>
                  <md-input
                    v-model="form.ocorrenciaAposentadoria"
                    type="text"
                    :maxlength="50"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-100"></div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.codExclusao"
                :rules="{
                  regex: /^(?:\d{1}|\d{2}|\d{3})$/,
                  min: 1,
                  max: 3
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Código Exclusão</label>
                  <md-input
                    v-model="form.codExclusao"
                    type="text"
                    :maxlength="3"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Código Exclusão deve ter de 1 até 3 digitos." }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <ValidationProvider
                name="form.dtExclusao"
                :rules="{
                  regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                  min: 10,
                  max: 10
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Data Exclusão</label>
                  <md-input
                    v-model="form.dtExclusao"
                    type="text"
                    :maxlength="10"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ "Data deve ter formato DD/MM/AAAA" }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.ocorrenciaExclusao"
                :rules="{
                  min: 3,
                  max: 50
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Ocorrência Exclusão</label>
                  <md-input
                    v-model="form.ocorrenciaExclusao"
                    type="text"
                    :maxlength="50"
                    :disabled="checkEditionDisabled()"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <br />
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                type="submit"
                v-show="showButton"
                class="md-raised md-success mt-4"
              >
                ATUALIZAR
              </md-button>
              &nbsp;
              <md-button
                type="button"
                v-show="showButton"
                class="md-raised mt-4"
                @click="cancelarButton"
              >
                CANCELAR
              </md-button>
              <span>&nbsp;</span>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </md-table>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import { NavTabsCard } from "@/components";
import Vue from "vue";
import Swal from "sweetalert2";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { siapeServidorMask } from "@/pages/Dashboard/js/siapeMask";
import { ESTADOS } from "@/pages/Dashboard/js/estados";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import mdSelectScrollResize from "@/pages/Dashboard/js/mdSelectScrollResize";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "Campo com formato inválido.";
  }
}
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}

localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    },
    isEdition: {
      type: Boolean
    },
    tipoProprietario: { type: String }
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      selectedServidorId: this.id,
      vinculos: null,
      estados: ESTADOS,
      editionMode: false,
      isFetching: true,
      showButton: false,
      isDisabledEdition: true,
      ufUpagSelect: "",
      ufUnidadeExercicioSelect: "",
      ufUnidadeLotacaoSelect: "",
      ufUnidadeLocalizacaoSelect: "",
      mensagem: "",
      form: {
        idServidor: this.id,
        idEntidade: null,
        nomeEntidade: null,
        nomeCompleto: null,
        situacao: null,
        cpfServidor: null,
        numTitEleitor: null,
        ctpsNum: null,
        ctpsSerie: null,
        ctpsUf: null,
        estadoCivil: null,
        nivelEscolaridade: null,
        dataNascimento: null,
        dataObito: null,
        diaAniversario: null,
        mesAniversario: null,
        pisPasep: null,
        rgServidor: null,
        rgUfServidor: null,
        rgOrgExpedidorServ: null,
        rgDataExpServ: null,
        sexoServidor: null,
        nomeMae: null,
        siapeServidor: null,
        siapeServidorDv: null,
        codOrgao: null,
        orgDescricao: null,
        upag: null,
        upagDescricao: null,
        siglaUpag: null,
        ufUpag: null,
        grupoCargo: null,
        cargoDescricao: null,
        classeCargo: null,
        padraoCargo: null,
        nivelCargo: null,
        uorgLotacao: null,
        nomeUnidadeLotacao: null,
        siglaUnidadeLotacao: null,
        ufUnidadeLotacao: null,
        uorgLocalizacao: null,
        nomeUnidadeLocalizacao: null,
        siglaUnidadeLocalizacao: null,
        ufUnidadeLocalizacao: null,
        uorgExercicio: null,
        nomeUnidadeExercicio: null,
        siglaUnidadeExercicio: null,
        ufUnidadeExercicio: null,
        sigRegJur: null,
        regimeJuridico: null,
        descricaoIngOrgao: null,
        dataIngressoOrgao: null,
        descricaoIngServPublico: null,
        dtIngServPublico: null,
        grupoAposentadoria: null,
        codAposentadoria: null,
        ocorrenciaAposentadoria: null,
        dtAposentadoria: null,
        codExclusao: null,
        ocorrenciaExclusao: null,
        dtExclusao: null,
        dataModificacao: null,
        dataCriacao: null
      },
      papel: "",
      componentKeyToRerender: 0
    };
  },
  beforeMount() {
    this.getPapel();
    this.loadServidor();
  },
  methods: {
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("servidor_publico", [
      "ActionSetSERVIDOR_PUBLICO",
      "ActionGetServidorPublicoById",
      "ActionPUTServidorPublico"
    ]),
    ...mapActions("entidade", ["ActionGetEntidadeById"]),
    checkPapel() {
      return this.papel === "ADMIN" ? true : false;
    },
    async loadForm() {
      this.form.idServidor = this.servidorPublicoSelected.idServidor;
      this.form.idEntidade = this.servidorPublicoSelected.idEntidade;
      this.form.nomeEntidade = this.servidorPublicoSelected.nomeEntidade;
      if (this.servidorPublicoSelected.siapeServidor) {
        this.form.siapeServidor =
          this.servidorPublicoSelected.siapeServidor === null
            ? null
            : this.servidorPublicoSelected.siapeServidor.trim();
      }

      if (this.servidorPublicoSelected.siapeServidorDv) {
        this.form.siapeServidorDv =
          this.servidorPublicoSelected.siapeServidorDv === null
            ? null
            : this.servidorPublicoSelected.siapeServidorDv.toString().trim();
      }

      this.form.codOrgao = this.servidorPublicoSelected.codOrgao;

      if (this.servidorPublicoSelected.orgDescricao) {
        this.form.orgDescricao =
          this.servidorPublicoSelected.orgDescricao === null
            ? null
            : this.servidorPublicoSelected.orgDescricao.toString().trim();
      }
      this.form.upag = this.servidorPublicoSelected.upag;

      if (this.servidorPublicoSelected.upagDescricao) {
        this.form.upagDescricao =
          this.servidorPublicoSelected.upagDescricao === null
            ? null
            : this.servidorPublicoSelected.upagDescricao.toString().trim();
      }
      if (this.servidorPublicoSelected.siglaUpag) {
        this.form.siglaUpag =
          this.servidorPublicoSelected.siglaUpag === null
            ? null
            : this.servidorPublicoSelected.siglaUpag.toString().trim();
      }

      if (this.servidorPublicoSelected.ufUpag) {
        let ufUpag = this.servidorPublicoSelected.ufUpag;
        let index = -1;
        let resultItem = this.estados.find(function(item, i) {
          if (item.shortname === ufUpag) {
            index = i;
            return item;
          }
        });
        this.form.ufUpag = resultItem.shortname;
        this.ufUpagSelect = resultItem.shortname;
      }

      if (this.servidorPublicoSelected.uorgExercicio) {
        this.form.uorgExercicio =
          this.servidorPublicoSelected.uorgExercicio === null
            ? null
            : this.servidorPublicoSelected.uorgExercicio.toString().trim();
      }

      if (this.servidorPublicoSelected.nomeUnidadeExercicio) {
        this.form.nomeUnidadeExercicio =
          this.servidorPublicoSelected.nomeUnidadeExercicio === null
            ? null
            : this.servidorPublicoSelected.nomeUnidadeExercicio
                .toString()
                .trim();
      }

      if (this.servidorPublicoSelected.siglaUnidadeExercicio) {
        this.form.siglaUnidadeExercicio =
          this.servidorPublicoSelected.siglaUnidadeExercicio === null
            ? null
            : this.servidorPublicoSelected.siglaUnidadeExercicio
                .toString()
                .trim();
      }
      if (this.servidorPublicoSelected.ufUnidadeExercicio) {
        let ufExerc = this.servidorPublicoSelected.ufUnidadeExercicio;
        let index = -1;
        let resultItem = this.estados.find(function(item, i) {
          if (item.shortname === ufExerc) {
            index = i;
            return item;
          }
        });
        this.form.ufUnidadeExercicio = resultItem.shortname;
        this.ufUnidadeExercicioSelect = resultItem.shortname;
      }

      if (this.servidorPublicoSelected.grupoCargo) {
        this.form.grupoCargo =
          this.servidorPublicoSelected.grupoCargo === null
            ? null
            : this.servidorPublicoSelected.grupoCargo.toString().trim();
      }

      if (this.servidorPublicoSelected.cargoDescricao) {
        this.form.cargoDescricao =
          this.servidorPublicoSelected.cargoDescricao === null
            ? null
            : this.servidorPublicoSelected.cargoDescricao.toString().trim();
      }

      if (this.servidorPublicoSelected.classeCargo) {
        this.form.classeCargo =
          this.servidorPublicoSelected.classeCargo === null
            ? null
            : this.servidorPublicoSelected.classeCargo.toString().trim();
      }

      if (this.servidorPublicoSelected.padraoCargo) {
        this.form.padraoCargo =
          this.servidorPublicoSelected.padraoCargo === null
            ? null
            : this.servidorPublicoSelected.padraoCargo.toString().trim();
      }

      if (this.servidorPublicoSelected.nivelCargo) {
        this.form.nivelCargo =
          this.servidorPublicoSelected.nivelCargo === null
            ? null
            : this.servidorPublicoSelected.nivelCargo.toString().trim();
      }

      this.form.uorgLotacao = this.servidorPublicoSelected.uorgLotacao;
      this.form.nomeUnidadeLotacao =
        this.servidorPublicoSelected.nomeUnidadeLotacao === null
          ? null
          : this.servidorPublicoSelected.nomeUnidadeLotacao;

      this.form.siglaUnidadeLotacao =
        this.servidorPublicoSelected.siglaUnidadeLotacao === null
          ? null
          : this.servidorPublicoSelected.siglaUnidadeLotacao;

      if (this.servidorPublicoSelected.ufUnidadeLotacao) {
        let ufUnidadeLotacao = this.servidorPublicoSelected.ufUnidadeLotacao;
        let index = -1;
        let resultItem = this.estados.find(function(item, i) {
          if (item.shortname === ufUnidadeLotacao) {
            index = i;
            return item;
          }
        });
        this.form.ufUnidadeLotacao = resultItem.shortname;
        this.ufUnidadeLotacaoSelect = resultItem.shortname;
      }

      if (this.servidorPublicoSelected.uorgLocalizacao) {
        this.form.uorgLocalizacao =
          this.servidorPublicoSelected.uorgLocalizacao === null
            ? null
            : this.servidorPublicoSelected.uorgLocalizacao.toString().trim();
      }

      if (this.servidorPublicoSelected.nomeUnidadeLocalizacao) {
        this.form.nomeUnidadeLocalizacao =
          this.servidorPublicoSelected.nomeUnidadeLocalizacao === null
            ? null
            : this.servidorPublicoSelected.nomeUnidadeLocalizacao
                .toString()
                .trim();
      }
      if (this.servidorPublicoSelected.siglaUnidadeLocalizacao) {
        this.form.siglaUnidadeLocalizacao =
          this.servidorPublicoSelected.siglaUnidadeLocalizacao === null
            ? null
            : this.servidorPublicoSelected.siglaUnidadeLocalizacao
                .toString()
                .trim();
      }

      if (this.servidorPublicoSelected.ufUnidadeLocalizacao) {
        let ufUnidadeLocalizacao = this.servidorPublicoSelected
          .ufUnidadeLocalizacao;
        let index = -1;
        let resultItem = this.estados.find(function(item, i) {
          if (item.shortname === ufUnidadeLocalizacao) {
            index = i;
            return item;
          }
        });
        this.form.ufUnidadeLocalizacao = resultItem.shortname;
        this.ufUnidadeLocalizacaoSelect = resultItem.shortname;
      }

      if (this.servidorPublicoSelected.descricaoIngOrgao) {
        this.form.descricaoIngOrgao =
          this.servidorPublicoSelected.descricaoIngOrgao === null
            ? null
            : this.servidorPublicoSelected.descricaoIngOrgao.toString().trim();
      }

      this.form.dataIngressoOrgao = this.servidorPublicoSelected.dataIngressoOrgao;

      if (this.servidorPublicoSelected.descricaoIngServPublico) {
        this.form.descricaoIngServPublico =
          this.servidorPublicoSelected.descricaoIngServPublico === null
            ? null
            : this.servidorPublicoSelected.descricaoIngServPublico
                .toString()
                .trim();
      }
      this.form.dtIngServPublico = this.servidorPublicoSelected.dtIngServPublico;

      if (this.servidorPublicoSelected.sigRegJur) {
        this.form.sigRegJur =
          this.servidorPublicoSelected.sigRegJur === null
            ? null
            : this.servidorPublicoSelected.sigRegJur.toString().trim();
      }
      this.form.regimeJuridico =
        this.servidorPublicoSelected.regimeJuridico === null
          ? null
          : this.servidorPublicoSelected.regimeJuridico;

      if (this.servidorPublicoSelected.grupoAposentadoria) {
        this.form.grupoAposentadoria =
          this.servidorPublicoSelected.grupoAposentadoria === null
            ? null
            : this.servidorPublicoSelected.grupoAposentadoria.toString().trim();
      }
      if (this.servidorPublicoSelected.codAposentadoria) {
        this.form.codAposentadoria =
          this.servidorPublicoSelected.codAposentadoria === null
            ? null
            : this.servidorPublicoSelected.codAposentadoria.toString().trim();
      }

      if (this.servidorPublicoSelected.ocorrenciaAposentadoria) {
        this.form.ocorrenciaAposentadoria =
          this.servidorPublicoSelected.ocorrenciaAposentadoria === null
            ? null
            : this.servidorPublicoSelected.ocorrenciaAposentadoria
                .toString()
                .trim();
      }

      this.form.dtAposentadoria = this.servidorPublicoSelected.dtAposentadoria;

      if (this.servidorPublicoSelected.codExclusao) {
        this.form.codExclusao =
          this.servidorPublicoSelected.codExclusao === null
            ? null
            : this.servidorPublicoSelected.codExclusao.toString().trim();
      }

      if (this.servidorPublicoSelected.ocorrenciaExclusao) {
        this.form.ocorrenciaExclusao =
          this.servidorPublicoSelected.ocorrenciaExclusao === null
            ? null
            : this.servidorPublicoSelected.ocorrenciaExclusao.toString().trim();
      }

      this.form.dtExclusao = this.servidorPublicoSelected.dtExclusao;
      this.isFetching = false;
      if (this.isEdition) {
        this.editionMode = true;
      }
    },

    async loadServidor() {
      if (
        typeof this.servidorPublicoSelected !== "undefined" &&
        this.servidorPublicoSelected !== "" &&
        this.servidorPublicoSelected !== null &&
        this.servidorPublicoSelected.idServidor
      ) {
        this.loadForm();
      } else {
        let id = this.id;
        let papel = this.papel;
        let path = window._Vue.$route.path;
        path = path.toString().split("/");
        if (path[3]) {
          id = path[3];
          const patt = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
          if (patt.test(id.toString())) {
            this.getServidorById(id, papel).then((res, papel) => {
              this.getEntidadeById(res.idEntidade).then(responseEntidade => {
                Vue.set(res, "idEntidade", responseEntidade.idEntidade);
                Vue.set(res, "nomeEntidade", responseEntidade.nomeEntidade);
                this.ActionSetSERVIDOR_PUBLICO(res);
                this.loadForm();
              });
            });
          } else {
            Swal.fire({
              title: "ATENÇÃO!",
              icon: "warning",
              text: "Por favor selecione um Servidor antes!",
              customClass: {
                confirmButton: "md-button md-warning btn-fill"
              },
              buttonsStyling: false
            });
            window._Vue.$router.push({ name: "Servidores" }).catch(err => {});
          }
        } else {
          Swal.fire({
            title: "ATENÇÃO!",
            icon: "warning",
            text: "Por favor selecione um Servidor antes!",
            customClass: {
              confirmButton: "md-button md-warning btn-fill"
            },
            buttonsStyling: false
          });
          window._Vue.$router.push({ name: "Servidores" }).catch(err => {});
        }
      }
    },
    async getEntidadeById(idEntidade) {
      try {
        let id = idEntidade;
        let papel = this.papel;
        let response = await this.ActionGetEntidadeById({ id, papel });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrada")) {
              Swal.fire({
                title: `idEntidade: ${idEntidade} não encontrada.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idEntidade: ${idEntidade}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    goToHistoricoVinculos() {
      if (
        this.servidorPublicoSelected.idServidor !== "" &&
        this.servidorPublicoSelected.idServidor !== null
      ) {
        let id = this.idServidor;
        window._Vue.$router
          .push({
            name: "Historico Vinculos",
            params: {
              //  id: id,
              isEditionIn: this.editionMode
            }
          })
          .catch(err => {});
      }
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getServidorById(idServidor, papelUsuario) {
      let id = idServidor;
      let papel = papelUsuario;
      let idEntidade = this.user_profile_state.idEntidade;
      try {
        let response = await this.ActionGetServidorPublicoById({
          id,
          papel,
          idEntidade
        });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não encontrado")) {
              Swal.fire({
                title: `idServidor: ${idServidor} não encontrado.`,
                icon: "error",
                text: this.mensagem,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised md-danger btn-fill"
                },
                buttonsStyling: false
              });
            } else {
              Swal.fire({
                icon: "error",
                title: `ERROR - idServidor: ${idServidor}`,
                text: `${this.mensagem}`,
                customClass: {
                  confirmButton: "md-button md-raised mt-4 md-success btn-fill"
                },
                buttonsStyling: false
              });
            }
          } else {
            return response.body;
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    onSelect() {
      console.log("item");
    },
    selectUfUpag(item) {
      let ufUpag = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === ufUpag) {
          index = i;
          return item;
        }
      });
      this.form.ufUpag = resultItem.shortname;
    },
    selectUfUnidadeExercicio(item) {
      let ufUnidadeExercicio = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === ufUnidadeExercicio) {
          index = i;
          return item;
        }
      });
      this.form.ufUnidadeExercicio = resultItem.shortname;
    },
    selectUfUnidadeLotacao(item) {
      let ufUnidadeLotacao = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === ufUnidadeLotacao) {
          index = i;
          return item;
        }
      });
      this.form.ufUnidadeLotacao = resultItem.shortname;
    },
    selectUfUnidadeLocalizacao(item) {
      let ufUnidadeLocalizacao = item;
      let index = -1;
      let resultItem = this.estados.find(function(item, i) {
        if (item.shortname === ufUnidadeLocalizacao) {
          index = i;
          return item;
        }
      });
      this.form.ufUnidadeLocalizacao = resultItem.shortname;
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    async submit() {
      try {
        let id = this.form.idServidor;
        let papel = this.papel;
        let form = this.form;
        let response = await this.ActionPUTServidorPublico({ id, papel, form });
        if (response.status === 200) {
          if (response.body.mensagem) {
            this.mensagem = response.body.mensagem;
            if (this.mensagem.includes("não")) {
              Swal.fire({
                //title: "Sucesso",
                icon: "warning",
                text: this.mensagem,
                showCancelButton: false,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false,
                confirmButtonText: "Ok"
              });
            } else {
              Swal.fire({
                //title: "Sucesso",
                icon: "success",
                text: this.mensagem,
                showCancelButton: false,
                customClass: {
                  confirmButton: "md-button md-success btn-fill"
                },
                buttonsStyling: false,
                confirmButtonText: "Ok"
              });
              this.editionMode = false;
              /*Object.keys(this.form).map(key => {
                console.log(key);
                return this.form[key];
              });*/
              this.ActionSetSERVIDOR_PUBLICO("");
              this.loadServidor();
            }
          } else {
            Swal.fire({
              //title: "Sucesso",
              icon: "warning",
              text: "OPSSS!! Algo deu Errado.",
              showCancelButton: false,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false,
              confirmButtonText: "Ok"
            });
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    mdSelectScrollResizeCall: function() {
      mdSelectScrollResize();
    },
    emitToParent(value) {
      this.$emit("edition", value);
    },
    cancelarButton() {
      this.loadServidor();
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    forceRerender() {
      this.componentKeyToRerender += 1;
    }
  },
  mounted() {
    //this.loadServidorForm();
  },
  watch: {
    "form.siapeServidor": function(val, oldVal) {
      if (val !== null && val.length > 0) {
        let siape = siapeServidorMask(val.toString());
        if (siape.length >= 8) {
          siape = siape.substring(0, siape.length - 1);
          this.forceRerender();
        }
        //console.log(siape.length);
        if (siape.length === 0) {
          //console.log(siape.length);
        }
        //console.log(siape);
        this.form.siapeServidor = siape;
      }
    },
    "form.dataIngressoOrgao": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dataIngressoOrgao = data;
    },
    "form.dtIngServPublico": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dtIngServPublico = data;
    },
    "form.dtAposentadoria": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dtAposentadoria = data;
    },
    "form.dtExclusao": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dtExclusao = data;
    },
    editionMode: function(val, oldVal) {
      let edition = val;
      if (edition) {
        this.isDisabledEdition = false;
        this.showButton = true;
        ///checkEditionDisabled();
      } else {
        this.isDisabledEdition = true;
        this.showButton = false;
      }
      this.emitToParent(edition);
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

::v-deep .md-input {
  text-transform: uppercase;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

::v-deep .md-radio.md-checked .md-radio-container {
  border-color: #000000 !important;
}

::v-deep .md-radio.md-checked .md-radio-container:after {
  background-color: #000000 !important;
}

.switch-div {
  display: inline-block;
  float: right;
}
</style>
