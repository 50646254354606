var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-table',{on:{"md-selected":_vm.onSelect},model:{value:(_vm.vinculos),callback:function ($$v) {_vm.vinculos=$$v},expression:"vinculos"}},[(!_vm.isFetching)?_c('div',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticStyle:{"padding-top":"15px","display":"inline-block"}},[_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_c('small',[_vm._v(_vm._s(_vm.tipoProprietario)+" id: "+_vm._s(_vm.form.idServidor))])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPapel()),expression:"checkPapel()"}],staticClass:"switch-div"},[_c('md-switch',{model:{value:(_vm.editionMode),callback:function ($$v) {_vm.editionMode=$$v},expression:"editionMode"}},[_vm._v("Modo Edição ")])],1),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Matrícula e Regime Juridico do Servidor ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.siapeServidor","rules":{
                regex: /^[0-9][0-9][0-9][0-9][1-9]{3}$/,
                min: 7,
                max: 7,
                required: true,
                numeric: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Siape Servidor")]),_c('md-input',{key:_vm.componentKeyToRerender,attrs:{"type":"text","maxlength":8,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.siapeServidor),callback:function ($$v) {_vm.$set(_vm.form, "siapeServidor", $$v)},expression:"form.siapeServidor"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Siape deve ter 7 digitos, ao menos 3 diferente de 0.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-10"},[_c('ValidationProvider',{attrs:{"name":"form.siapeServidorDv","rules":{
                regex: /^\d{1}$/,
                min: 1,
                max: 1
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("DV Siape")]),_c('md-input',{attrs:{"type":"text","maxlength":1,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.siapeServidorDv),callback:function ($$v) {_vm.$set(_vm.form, "siapeServidorDv", $$v)},expression:"form.siapeServidorDv"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("DV Siape deve ter 1 digito.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.sigRegJur","rules":{
                min: 3,
                max: 20,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Sigla Regime Jurídico")]),_c('md-input',{attrs:{"type":"text","maxlength":20,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.sigRegJur),callback:function ($$v) {_vm.$set(_vm.form, "sigRegJur", $$v)},expression:"form.sigRegJur"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.regimeJuridico","rules":{
                min: 3,
                max: 20,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Regime Jurídico")]),_c('md-input',{attrs:{"type":"text","maxlength":20,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.regimeJuridico),callback:function ($$v) {_vm.$set(_vm.form, "regimeJuridico", $$v)},expression:"form.regimeJuridico"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkPapel()),expression:"checkPapel()"}],staticClass:"md-raised md-info mt-3",on:{"click":function($event){return _vm.goToHistoricoVinculos()}}},[_c('md-icon',[_vm._v("history")]),_vm._v("Histórico de Vinculos ")],1)],1)]),_c('br'),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Orgão ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.codOrgao","rules":{
                regex: /^\d{5}$/,
                min: 5,
                max: 5,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Código Orgão")]),_c('md-input',{attrs:{"type":"text","maxlength":5,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.codOrgao),callback:function ($$v) {_vm.$set(_vm.form, "codOrgao", $$v)},expression:"form.codOrgao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Código Orgão deve ter 5 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.orgDescricao","rules":{
                alpha_spaces: true,
                min: 3,
                max: 100,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Orgão Descrição")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.orgDescricao),callback:function ($$v) {_vm.$set(_vm.form, "orgDescricao", $$v)},expression:"form.orgDescricao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.descricaoIngOrgao","rules":{
                alpha_spaces: true,
                min: 3,
                max: 100
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Descrição Ingresso Orgão")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.descricaoIngOrgao),callback:function ($$v) {_vm.$set(_vm.form, "descricaoIngOrgao", $$v)},expression:"form.descricaoIngOrgao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.dataIngressoOrgao","rules":{
                regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                min: 10,
                max: 10,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Data Ingresso Orgão")]),_c('md-input',{attrs:{"type":"text","maxlength":10,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.dataIngressoOrgao),callback:function ($$v) {_vm.$set(_vm.form, "dataIngressoOrgao", $$v)},expression:"form.dataIngressoOrgao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Data deve ter formato DD/MM/AAAA")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.descricaoIngServPublico","rules":{
                alpha_spaces: true,
                min: 3,
                max: 100
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Descrição Ingresso Serviço Público")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.descricaoIngServPublico),callback:function ($$v) {_vm.$set(_vm.form, "descricaoIngServPublico", $$v)},expression:"form.descricaoIngServPublico"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"form.dtIngServPublico","rules":{
                regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                min: 10,
                max: 10,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Data Ingresso Serviço Público")]),_c('md-input',{attrs:{"type":"text","maxlength":10,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.dtIngServPublico),callback:function ($$v) {_vm.$set(_vm.form, "dtIngServPublico", $$v)},expression:"form.dtIngServPublico"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Data deve ter formato DD/MM/AAAA")+" ")]):_vm._e()]}}],null,true)})],1)]),_c('br'),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Unidade Pagadora ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.upag","rules":{
                regex: /^\d{9}$/,
                min: 9,
                max: 9,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Pagadora")]),_c('md-input',{attrs:{"type":"text","maxlength":9,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.upag),callback:function ($$v) {_vm.$set(_vm.form, "upag", $$v)},expression:"form.upag"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Unidade Pagadora deve ter 9 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.upagDescricao","rules":{
                min: 3,
                max: 100,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Pagadora Descrição")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.upagDescricao),callback:function ($$v) {_vm.$set(_vm.form, "upagDescricao", $$v)},expression:"form.upagDescricao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"form.siglaUpag","rules":{
                min: 3,
                max: 20,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Sigla Unidade Pagadora")]),_c('md-input',{attrs:{"type":"text","maxlength":20,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.siglaUpag),callback:function ($$v) {_vm.$set(_vm.form, "siglaUpag", $$v)},expression:"form.siglaUpag"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.ufUpag","rules":{
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',{attrs:{"for":"ufUpagSelect"}},[_vm._v("UF da Upag")]),_c('md-select',{attrs:{"id":"selectOptionFieldUfUpag","name":"ufUpagSelect","disabled":_vm.checkEditionDisabled()},on:{"md-opened":function($event){return _vm.mdSelectScrollResizeCall()},"md-selected":_vm.selectUfUpag},model:{value:(_vm.ufUpagSelect),callback:function ($$v) {_vm.ufUpagSelect=$$v},expression:"ufUpagSelect"}},_vm._l((_vm.estados),function(item){return _c('md-option',{key:item.shortname,attrs:{"label":item.name,"value":item.shortname}},[_vm._v(" "+_vm._s(item.shortname)+" ")])}),1)],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('br'),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Unidade Exercício ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.uorgExercicio","rules":{
                regex: /^\d{9}$/,
                min: 9,
                max: 9,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Exercício")]),_c('md-input',{attrs:{"type":"text","maxlength":9,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.uorgExercicio),callback:function ($$v) {_vm.$set(_vm.form, "uorgExercicio", $$v)},expression:"form.uorgExercicio"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Unidade Exercício deve ter 9 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.nomeUnidadeExercicio","rules":{
                min: 3,
                max: 100,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Exercício Descrição")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.nomeUnidadeExercicio),callback:function ($$v) {_vm.$set(_vm.form, "nomeUnidadeExercicio", $$v)},expression:"form.nomeUnidadeExercicio"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"form.siglaUnidadeExercicio","rules":{
                min: 3,
                max: 20,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Sigla Unidade Exercício")]),_c('md-input',{attrs:{"type":"text","maxlength":20,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.siglaUnidadeExercicio),callback:function ($$v) {_vm.$set(_vm.form, "siglaUnidadeExercicio", $$v)},expression:"form.siglaUnidadeExercicio"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.ufUnidadeExercicio","rules":{
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',{attrs:{"for":"ufUnidadeExercicioSelect"}},[_vm._v("UF do Exercício")]),_c('md-select',{attrs:{"id":"selectOptionFieldUfUnidadeExercicio","name":"ufUnidadeExercicioSelect","disabled":_vm.checkEditionDisabled()},on:{"md-selected":_vm.selectUfUnidadeExercicio,"md-opened":function($event){return _vm.mdSelectScrollResizeCall()}},model:{value:(_vm.ufUnidadeExercicioSelect),callback:function ($$v) {_vm.ufUnidadeExercicioSelect=$$v},expression:"ufUnidadeExercicioSelect"}},_vm._l((_vm.estados),function(item){return _c('md-option',{key:item.shortname,attrs:{"label":item.name,"value":item.shortname}},[_vm._v(" "+_vm._s(item.shortname)+" ")])}),1)],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('br'),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Cargo ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.grupoCargo","rules":{
                regex: /^\d{6}$/,
                min: 6,
                max: 6,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Grupo do Cargo")]),_c('md-input',{attrs:{"type":"text","maxlength":6,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.grupoCargo),callback:function ($$v) {_vm.$set(_vm.form, "grupoCargo", $$v)},expression:"form.grupoCargo"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Grupo do Cargo deve ter 6 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.cargoDescricao","rules":{
                min: 3,
                max: 100,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Cargo Descrição")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.cargoDescricao),callback:function ($$v) {_vm.$set(_vm.form, "cargoDescricao", $$v)},expression:"form.cargoDescricao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-10"},[_c('ValidationProvider',{attrs:{"name":"form.classeCargo","rules":{
                min: 1,
                max: 2,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Classe do Cargo")]),_c('md-input',{attrs:{"type":"text","maxlength":2,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.classeCargo),callback:function ($$v) {_vm.$set(_vm.form, "classeCargo", $$v)},expression:"form.classeCargo"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-10"},[_c('ValidationProvider',{attrs:{"name":"form.padraoCargo","rules":{
                min: 1,
                max: 3
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Padrão do Cargo")]),_c('md-input',{attrs:{"type":"text","maxlength":3,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.padraoCargo),callback:function ($$v) {_vm.$set(_vm.form, "padraoCargo", $$v)},expression:"form.padraoCargo"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-10"},[_c('ValidationProvider',{attrs:{"name":"form.nivelCargo","rules":{
                min: 1,
                max: 2,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Nível do Cargo")]),_c('md-input',{attrs:{"type":"text","maxlength":2,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.nivelCargo),callback:function ($$v) {_vm.$set(_vm.form, "nivelCargo", $$v)},expression:"form.nivelCargo"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('br'),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Lotação ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.uorgLotacao","rules":{
                regex: /^\d{9}$/,
                min: 9,
                max: 9
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Lotação")]),_c('md-input',{attrs:{"type":"text","maxlength":9,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.uorgLotacao),callback:function ($$v) {_vm.$set(_vm.form, "uorgLotacao", $$v)},expression:"form.uorgLotacao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Unidade Lotação deve ter 9 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.nomeUnidadeLotacao","rules":{
                min: 3,
                max: 100
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Lotação Descrição")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.nomeUnidadeLotacao),callback:function ($$v) {_vm.$set(_vm.form, "nomeUnidadeLotacao", $$v)},expression:"form.nomeUnidadeLotacao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"form.sigleUnidadeLotacao","rules":{
                min: 3,
                max: 20
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Sigla Unidade Lotação")]),_c('md-input',{attrs:{"type":"text","maxlength":20,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.siglaUnidadeLotacao),callback:function ($$v) {_vm.$set(_vm.form, "siglaUnidadeLotacao", $$v)},expression:"form.siglaUnidadeLotacao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.ufUnidadeLotacao","rules":{
                required: false
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',{attrs:{"for":"ufUnidadeLotacaoSelect"}},[_vm._v("UF da Lotação")]),_c('md-select',{attrs:{"id":"selectOptionFieldUfUnidadeLotacao","name":"ufUnidadeLotacaoSelect","disabled":_vm.checkEditionDisabled()},on:{"md-selected":_vm.selectUfUnidadeLotacao,"md-opened":function($event){return _vm.mdSelectScrollResizeCall()}},model:{value:(_vm.ufUnidadeLotacaoSelect),callback:function ($$v) {_vm.ufUnidadeLotacaoSelect=$$v},expression:"ufUnidadeLotacaoSelect"}},_vm._l((_vm.estados),function(item){return _c('md-option',{key:item.shortname,attrs:{"label":item.name,"value":item.shortname}},[_vm._v(" "+_vm._s(item.shortname)+" ")])}),1)],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('br'),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Localização ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.uorgLocalizacao","rules":{
                regex: /^\d{9}$/,
                min: 9,
                max: 9,
                numeric: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Localização")]),_c('md-input',{attrs:{"type":"text","maxlength":9,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.uorgLocalizacao),callback:function ($$v) {_vm.$set(_vm.form, "uorgLocalizacao", $$v)},expression:"form.uorgLocalizacao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Unidade Localização deve ter 9 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.nomeUnidadeLocalizacao","rules":{
                min: 3,
                max: 100
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Unidade Localização Descrição")]),_c('md-input',{attrs:{"type":"text","maxlength":100,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.nomeUnidadeLocalizacao),callback:function ($$v) {_vm.$set(_vm.form, "nomeUnidadeLocalizacao", $$v)},expression:"form.nomeUnidadeLocalizacao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"form.siglaUnidadeLocalizacao","rules":{
                min: 3,
                max: 20
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Sigla Unidade Localização")]),_c('md-input',{attrs:{"type":"text","maxlength":20,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.siglaUnidadeLocalizacao),callback:function ($$v) {_vm.$set(_vm.form, "siglaUnidadeLocalizacao", $$v)},expression:"form.siglaUnidadeLocalizacao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.ufUnidadeLocalizacao","rules":{
                required: false
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',{attrs:{"for":"ufUnidadeLocalizacaoSelect"}},[_vm._v("UF da Localização")]),_c('md-select',{attrs:{"id":"selectOptionFieldUfUnidadeLocalizacao","name":"ufUnidadeLocalizacaoSelect","disabled":_vm.checkEditionDisabled()},on:{"md-selected":_vm.selectUfUnidadeLocalizacao,"md-opened":function($event){return _vm.mdSelectScrollResizeCall()}},model:{value:(_vm.ufUnidadeLocalizacaoSelect),callback:function ($$v) {_vm.ufUnidadeLocalizacaoSelect=$$v},expression:"ufUnidadeLocalizacaoSelect"}},_vm._l((_vm.estados),function(item){return _c('md-option',{key:item.shortname,attrs:{"label":item.name,"value":item.shortname}},[_vm._v(" "+_vm._s(item.shortname)+" ")])}),1)],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('br'),_c('span',[_c('h5',{staticClass:"title",staticStyle:{"font-weight":"350","padding-bottom":"10px"}},[_vm._v(" Aposentadoria ")])]),_c('div',{staticClass:"md-layout",attrs:{"id":"agrupamentoCampos"}},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"form.grupoAposentadoria","rules":{
                regex: /^(?:\d{1}|\d{2}|\d{3})$/,
                min: 1,
                max: 3
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Grupo Aposentadoria")]),_c('md-input',{attrs:{"type":"text","maxlength":3,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.grupoAposentadoria),callback:function ($$v) {_vm.$set(_vm.form, "grupoAposentadoria", $$v)},expression:"form.grupoAposentadoria"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Grupo Aposentadoria deve ter de 1 até 3 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.codAposentadoria","rules":{
                min: 1,
                max: 3
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Código Aposentadoria")]),_c('md-input',{attrs:{"type":"text","maxlength":3,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.codAposentadoria),callback:function ($$v) {_vm.$set(_vm.form, "codAposentadoria", $$v)},expression:"form.codAposentadoria"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.dtAposentadoria","rules":{
                regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                min: 10,
                max: 10
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Data Aposentadoria")]),_c('md-input',{attrs:{"type":"text","maxlength":10,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.dtAposentadoria),callback:function ($$v) {_vm.$set(_vm.form, "dtAposentadoria", $$v)},expression:"form.dtAposentadoria"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Data deve ter formato DD/MM/AAAA")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-20"},[_c('ValidationProvider',{attrs:{"name":"form.ocorrenciaAposentadoria","rules":{
                min: 3,
                max: 50
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Ocorrência Aposentadoria")]),_c('md-input',{attrs:{"type":"text","maxlength":50,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.ocorrenciaAposentadoria),callback:function ($$v) {_vm.$set(_vm.form, "ocorrenciaAposentadoria", $$v)},expression:"form.ocorrenciaAposentadoria"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-100"}),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.codExclusao","rules":{
                regex: /^(?:\d{1}|\d{2}|\d{3})$/,
                min: 1,
                max: 3
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Código Exclusão")]),_c('md-input',{attrs:{"type":"text","maxlength":3,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.codExclusao),callback:function ($$v) {_vm.$set(_vm.form, "codExclusao", $$v)},expression:"form.codExclusao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Código Exclusão deve ter de 1 até 3 digitos.")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-15"},[_c('ValidationProvider',{attrs:{"name":"form.dtExclusao","rules":{
                regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                min: 10,
                max: 10
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Data Exclusão")]),_c('md-input',{attrs:{"type":"text","maxlength":10,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.dtExclusao),callback:function ($$v) {_vm.$set(_vm.form, "dtExclusao", $$v)},expression:"form.dtExclusao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s("Data deve ter formato DD/MM/AAAA")+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-33"},[_c('ValidationProvider',{attrs:{"name":"form.ocorrenciaExclusao","rules":{
                min: 3,
                max: 50
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Ocorrência Exclusão")]),_c('md-input',{attrs:{"type":"text","maxlength":50,"disabled":_vm.checkEditionDisabled()},model:{value:(_vm.form.ocorrenciaExclusao),callback:function ($$v) {_vm.$set(_vm.form, "ocorrenciaExclusao", $$v)},expression:"form.ocorrenciaExclusao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('br'),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"md-raised md-success mt-4",attrs:{"type":"submit"}},[_vm._v(" ATUALIZAR ")]),_vm._v("   "),_c('md-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showButton),expression:"showButton"}],staticClass:"md-raised mt-4",attrs:{"type":"button"},on:{"click":_vm.cancelarButton}},[_vm._v(" CANCELAR ")]),_c('span',[_vm._v(" ")])],1)])])]}}],null,false,3416638517)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }